export const POSTHOG_EVENT = {
  APP_ERROR: 'app_error',
  FEEDBACK_SENT: 'feedback_sent',
  PAGE_CHANGE: 'page_change',

  // home page
  SEARCH_START: 'search_start',
  SEARCH_COMPLETE: 'search_complete',
  SEARCH_EXAMPLE_SELECT: 'search_example_select',
  SEARCH_HISTORY_SELECT: 'search_history_select',
  GO_TO_HOME: 'go_to_home',
  GO_TO_RESULTS: 'go_to_results', // not implemented

  // search result page
  FILTER_TAG_SELECT: 'filter_tag_select',
  FILTER_TAG_DESELECT: 'filter_tag_deselect',
  FILTER_RESET: 'filter_reset',
  FILTER_APPLY: 'filter_apply',
  FILTER_START_DATE_SELECT: 'filter_start_date_select',
  FILTER_END_DATE_SELECT: 'filter_end_date_select',
  SEARCH_RESULT_EXPAND: 'search_result_expand',
  SEARCH_RESULT_OPEN: 'search_result_open',
  SEARCH_TRY_AGAIN: 'search_try_again',
  NAVIGATE_BACK_HOME: 'navigate_back_home',
  SEARCH_RESULT_THUMBS_UP: 'search_result_thumbs_up',
  SEARCH_RESULT_THUMBS_DOWN: 'search_result_thumbs_down',
  SEARCH_RESULT_THUMBS_RESET: 'search_result_thumbs_reset',
  DATABASE_INFO_MODAL_OPEN: 'database_info_modal_open',

  // document result page
  DOWNLOAD_PDF_WITH_HIGHLIGHTING: 'download_pdf_with_highlighting',
  DOWNLOAD_PDF_WITHOUT_HIGHLIGHTING: 'download_pdf_without_highlighting',
  DOWNLOAD_PDF_ONLY_HIGHLIGHTS: 'download_pdf_only_highlights',
  PRINT_PDF_WITH_HIGHLIGHTING: 'print_pdf_with_highlighting',
  PRINT_PDF_WITHOUT_HIGHLIGHTING: 'print_pdf_without_highlighting',
  PRINT_PDF_ONLY_HIGHLIGHTS: 'print_pdf_only_highlights',
  DOCUMENT_SNIPPET_CLICK: 'document_snippet_click', // not implemented
  NAVIGATE_BACK_TO_SEARCH_RESULT: 'navigate_back_to_search_result', // not implemented
  RELATION_CLICK: 'relation_click', // not implemented
  TEXT_SELECTION_SEARCH: 'text_selection_search', // not implemented
  SEARCH_IN_DOCUMENT: 'search_in_document', // not implemented
  OPEN_RELATED_DOCUMENT: 'open_related_document', // not implemented
  GO_TO_RELATED_DOCUMENT: 'go_to_related_document', // not implemented

  // signup flow
  NEW_SIGNUP: 'new_signup',
  EMAIL_VERIFICATION_SKIPPED: 'email_verification_skipped',
  NEW_EMAIL_VERIFICATION: 'new_email_verification',
  RESEND_EMAIL_VERIFICATION: 'resend_email_verification',
  PASSWORD_RESET: 'password_reset',
  FORGOT_PASSWORD: 'forgot_password',
  LOGIN: 'login',
  LOGOUT: 'logout',
  LOGIN_ERROR: 'login_error',
  FORGOT_PASSWORD_EMAIL_SENT: 'forgot_password_email_sent',

  // help button
  OPEN_QUICK_GUIDE: 'open_quick_guide',
  CONTACT_TEAM: 'contact_team',
};
